<template>
  <div class="ele-body">
    <el-card shadow="never">
      <ele-pro-table
        ref="table"
        :needPage="false"
        :datasource="url"
        :columns="columns"
        :response="response"
        :selection.sync="selection"
      >
        <!-- 表头工具栏 -->
        <template slot="image" slot-scope="{row}">
          <el-avatar style="width:auto;height: auto" shape="square"  :src="$baseImgUrl + row.image"/>
        </template>
        <template slot="toolbar">
          <el-button
            @click="openEdit(null)"
            type="primary"
            icon="el-icon-plus"
            class="ele-btn-icon"
            size="small"
            >添加
          </el-button>
          <el-button
            @click="removeBatch"
            type="danger"
            icon="el-icon-delete"
            class="ele-btn-icon"
            size="small"
            >删除
          </el-button>
        </template>
        <!-- 操作列 -->
        <template slot="action" slot-scope="{ row }">
          <div v-if="row.role_id !== 1">
            <el-link
              @click="openEdit(row)"
              icon="el-icon-edit"
              type="primary"
              :underline="false"
              >修改
            </el-link>
            <el-link
              @click="openAuth(row)"
              icon="el-icon-finished"
              type="primary"
              :underline="false"
              >分配权限
            </el-link>
            <el-popconfirm
              title="确定要删除吗？"
              @confirm="remove(row)"
              class="ele-action"
            >
              <el-link
                slot="reference"
                icon="el-icon-delete"
                type="danger"
                :underline="false"
                >删除
              </el-link>
            </el-popconfirm>
          </div>
        </template>
      </ele-pro-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <role-edit :visible.sync="showEdit" :data="current" @done="reload" />
    <!-- 权限分配弹窗 -->
    <role-auth :visible.sync="showAuth" :data="current" @done="reload"/>
  </div>
</template>

<script>
import RoleEdit from "./level-edit";
import RoleAuth from "./level-auth";

export default {
  name: "Level",
  components: { RoleEdit, RoleAuth },
  data() {
    return {
      // 表格数据接口
      url: "/Admin/level",
      response: {
        statusCode: 200,
        dataName: "data",
      },
      // 表格列配置
      columns: [
        {
          columnKey: "selection",
          type: "selection",
          width: 45,
          align: "center",
          fixed: "left",
        },
        {
          columnKey: "index",
          type: "index",
          width: 45,
          align: "center",
          fixed: "left",
          showOverflowTooltip: true,
        },
        {
          prop: "name",
          label: "等级",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: 'image',
          label: '图标',
          showOverflowTooltip: true,
          minWidth: 150,
          slot: 'image'
        },
        {
          prop: "remark",
          label: "备注",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "level_description",
          label: "等级描述",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          prop: "created_at",
          label: "创建时间",
          sortable: "custom",
          showOverflowTooltip: true,
          minWidth: 110,
        },
        {
          columnKey: "action",
          label: "操作",
          width: 230,
          align: "center",
          resizable: false,
          slot: "action",
        },
      ],
      // 表格选中数据
      selection: [],
      // 当前编辑数据
      current: null,
      // 是否显示编辑弹窗
      showEdit: false,
      // 是否显示导入弹窗
      showAuth: false,
    };
  },
  methods: {
    /* 刷新表格 */
    reload() {
      this.$refs.table.reload();
    },
    /* 显示编辑 */
    openEdit(row) {
      this.current = row;
      this.showEdit = true;
    },
    /* 显示分配权限 */
    openAuth(row) {
      this.current = row;
      this.showAuth = true;
    },
    /* 删除 */
    remove(row) {
      const loading = this.$loading({ lock: true });
      this.$http
        .delete("/Admin/level/destroy/" + row.id)
        .then((res) => {
          loading.close();
          if (res.data.code === 200) {
            this.$message({ type: "success", message: res.data.msg });
            this.reload();
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((e) => {
          loading.close();
          this.$message.error(e.message);
        });
    },
    /* 批量删除 */
    removeBatch() {
      if (!this.selection.length) {
        this.$message.error("请至少选择一条数据");
        return;
      }
      this.$confirm("确定要删除选中的等级吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          const loading = this.$loading({ lock: true });
          this.$http
            .delete(
              "/Admin/level/destroy/" +
                this.selection.map((d) => d.id).toString()
            )
            .then((res) => {
              loading.close();
              if (res.data.code === 200) {
                this.$message({ type: "success", message: res.data.msg });
                this.reload();
              } else {
                this.$message.error(res.data.msg);
              }
            })
            .catch((e) => {
              loading.close();
              this.$message.error(e.message);
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>
